 <kodit-dugovanje-notification *ngIf="showDugovanjeNotification"></kodit-dugovanje-notification>

<!--<div class="p-mb-3 p-fluid" style="margin-top: -18px">-->
<!--  <p-messages severity="info">-->
<!--    <ng-template pTemplate>-->
<!--      <div class="p-d-flex p-jc-between" style="width: 100%">-->
<!--        <div class="p-d-flex p-flex-column gap-6">-->
<!--          <div>-->
<!--            Kao postojeći klijent, paket "Arhivska knjiga i delovodnik",-->
<!--            dobijate sa <strong>20% popusta</strong> narednih-->
<!--            <strong>12 meseci</strong>.-->
<!--          </div>-->
<!--          <div>-->
<!--            <strong>Kontaktirajte nas</strong> najkasnije do-->
<!--            <strong>31.1.2024.</strong> kako biste ostvarili pravo na-->
<!--            popust.-->
<!--          </div>-->
<!--          <ul class="p-m-0">-->
<!--            <li>-->
<!--              Pozovite broj telefona-->
<!--              <a-->
<!--                class="p-text-bold"-->
<!--                href="tel:+381606699361"-->
<!--                style="color: #044868"-->
<!--              >060/66-99-361</a-->
<!--              >-->
<!--            </li>-->
<!--            <li>Obavestite našu tehničku podršku</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="p-d-flex p-ai-center p-jc-center">-->
<!--            <span-->
<!--              class="p-message-icon pi pi-info-circle"-->
<!--              style="font-size: 2.5rem"-->
<!--            ></span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-messages>-->
<!--</div>-->
