import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  IKompenzacijaDto,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KompenzacijaService {

  stareVrednostiDuznika : number[] = [];
  stareVrednostiPoverioca : number[] = [];
  brojObavezaDuznika: number;
  brojObavezaPoverioca: number;
  /**
   * Postavljanje forme
   */
  form$ = new BehaviorSubject<KompenzacijaForm>(null);
  set setForm(value: KompenzacijaForm) {
    this.form$.next(value);
  }

  getStrankaMb() {
    return this.form$.value.value.stranka.mb;
  }

  get getForm(): Observable<KompenzacijaForm> {
    return this.form$.asObservable();
  }

  get getFormObject(): KompenzacijaForm {
    return this.form$.getValue();
  }

  get getObavezePremaPoveriocuArray(): FormArray {
    if (!this.getFormObject) {
      return new FormArray([]);
    }
    return this.getFormObject.controls.obavezePremaPoveriocu as FormArray;
  }

  get getObavezePremaDuznikuArray(): FormArray {
    if (!this.getFormObject) {
      return new FormArray([]);
    }
    return this.getFormObject.controls.obavezePremaDuzniku as FormArray;
  }

  dodajStareVrednosti() : void{
    this.getFormObject.controls.obavezePremaDuzniku.value.forEach(x => {
      if(x.ukupanIznosRacuna != null){
        this.stareVrednostiDuznika.push(x.ukupanIznosRacuna);
      }
    });
    this.brojObavezaDuznika = this.stareVrednostiDuznika.length;

    this.getFormObject.controls.obavezePremaPoveriocu.value.forEach(x => {
      if(x.ukupanIznosRacuna != null){
        this.stareVrednostiPoverioca.push(x.ukupanIznosRacuna);
      }
    });
    this.brojObavezaPoverioca = this.stareVrednostiPoverioca.length;
  }

  dodajUStareVrednosti() : void {
    if(this.brojObavezaDuznika < this.getFormObject.controls.obavezePremaDuzniku.value.length){
      var obaveze = this.getFormObject.controls.obavezePremaDuzniku.value;
      for(var i = this.brojObavezaDuznika; i < obaveze.length; i++){
        if(obaveze[i].ukupanIznosRacuna != null){
          this.stareVrednostiDuznika.push(obaveze[i].ukupanIznosRacuna);
        }
      }
      this.brojObavezaDuznika = this.stareVrednostiDuznika.length;
    }

    if(this.brojObavezaPoverioca < this.getFormObject.controls.obavezePremaPoveriocu.value.length){
      var obaveze = this.getFormObject.controls.obavezePremaPoveriocu.value;
      for(var i = this.brojObavezaPoverioca; i < obaveze.length; i++){
        if(obaveze[i].ukupanIznosRacuna != null){
          this.stareVrednostiPoverioca.push(obaveze[i].ukupanIznosRacuna);
        }
      }
      this.brojObavezaPoverioca = this.stareVrednostiPoverioca.length;
    }
    
  }

  izbaciIzNizaStarih(rowIndex: number, nazivPravca: string) : void {
    if(nazivPravca == "obavezePremaPoveriocu"){
      this.stareVrednostiPoverioca.splice(rowIndex, 1);
    }else{
      this.stareVrednostiDuznika.splice(rowIndex, 1);
    }
  }

  zameniStareVrednosti(nazivPravca: string, rowIndex: number) : void {
    if(nazivPravca == "obavezePremaPoveriocu" && this.brojObavezaPoverioca != 0){
      this.stareVrednostiPoverioca[rowIndex] = this.getFormObject.controls.obavezePremaPoveriocu.value[rowIndex].ukupanIznosRacuna ?? null;
    }else if(nazivPravca == "obavezePremaDuzniku" && this.brojObavezaPoverioca != 0){
      this.stareVrednostiDuznika[rowIndex] = this.getFormObject.controls.obavezePremaDuzniku.value[rowIndex].ukupanIznosRacuna ?? null;
    }
  }

  resetObaveze() : void{
    this.stareVrednostiDuznika = [];
    this.stareVrednostiPoverioca = [];
  }
} 

export declare type KompenzacijaForm = FormGroupTypeSafe<IKompenzacijaDto>;
