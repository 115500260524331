<div class="p-d-flex p-flex-column p-flex-md-row storno-form">
  <div class="p-d-flex flex-1">
    <div class="pdf-data" [innerHTML]="pdfInnerHtml"></div>
  </div>
  <div class="p-d-flex flex-1">
    <form [formGroup]="form" class="pdf-form">
      <div class="p-d-flex p-flex-column">
        <div class="p-d-flex p-text-center pitanje">
          Da li ste sigurni da želite da stornirate račun broj&nbsp;
          <span class="p-text-bold">{{ brojRacuna }}?</span>
        </div>
        <div class="p-fluid p-mt-5">
          <kodit-field fieldLabel="Storno broj" fieldLabelFor="storno-broj">
            <kodit-text-input
              formCtrlId="storno-broj"
              [formCtrl]="form.controls.stornoBroj"
            ></kodit-text-input>
          </kodit-field>
        </div>
        <div class="p-fluid">
          <kodit-field fieldLabel="Storno komentar" fieldLabelFor="komentar">
            <kodit-textarea-input
              formCtrlId="komentar"
              [formCtrl]="form.controls.komentar"
            ></kodit-textarea-input>
          </kodit-field>
        </div>
        <div class="p-fluid">
          <kodit-field fieldLabel="Od" fieldLabelFor="From">
            <kodit-email-dropdown [formCtrl]="form.controls.fromId">
            </kodit-email-dropdown>
          </kodit-field>
        </div>
        <div class="p-fluid">
          <kodit-field
            fieldLabel="Pošalji izjavu na mail"
            fieldLabelFor="email"
          >
            <kodit-email-input
              [formCtrl]="form.controls.to"
            ></kodit-email-input>
          </kodit-field>
        </div>
        <div class="p-mb-3 p-fluid" style="margin-right: -18px">
          <p-messages severity="info">
            <ng-template pTemplate>
              <div class="p-d-flex p-jc-between" style="width: 100%">
                <div class="p-d-flex p-flex-column gap-6">
                  <div>
                    Polje za email adresu je obavezno. Kada ga popunite, izjava
                    za odbitak PDV se šalje na unetu adresu.
                  </div>
                </div>
              </div>
            </ng-template>
          </p-messages>
        </div>
      </div>
    </form>
  </div>
</div>
