<div class="p-d-inline-flex" *ngIf="vezaniRacuni && vezaniRacuni.length > 0">
  <label style="line-height: 20px;">Veza:</label>
  <div class="kodit-chip-container p-ml-2" style="display: flex; width: 40vw; flex-wrap: wrap;" card-body>
    <ng-container>
      <div
        class="p-mr-2 my-p-chip"
        *ngFor="let x of vezaniRacuni; index as i"
      >
        <ng-container>
          <div>
            <button
              pButton
              type="button"
              [label]="x.label"
              class="p-button-link"
              style="padding: 2px;"
              pTooltip="Prikaži račun"
              tooltipPosition="bottom"
              (click)="handleGoToRacun(x)"
            ></button>
          </div>
          <span
            class="fa-solid fa-xmark my-p-chip-remove-icon p-button-danger"
            pTooltip="Ukloni oznaku iz računa"
            tooltipPosition="bottom"
            (click)="handleRemoveRacun(i)"
          ></span>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <!-- <div class="p-d-flex p-flex-column">
    <div class="p-d-flex gap-12" *ngFor="let x of vezaniRacuni; index as i">
      <button
        pButton
        type="button"
        [label]="x.label"
        class="p-button-link p-pt-0"
        pTooltip="Prikaži račun"
        tooltipPosition="bottom"
        (click)="handleGoToRacun(x)"
      ></button>
      <button
        pButton
        type="button"
        icon="fa-solid fa-xmark"
        class="p-button-rounded p-button-danger p-button-text"
        pTooltip="Uklonite vezani račun"
        tooltipPosition="bottom"
        (click)="handleRemoveRacun(i)"
      ></button>
    </div>
  </div> -->
</div>
