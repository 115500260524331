import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PdvEvidencijaPageComponent } from './pdv-evidencija-page/pdv-evidencija-page.component';
import { PdvEvidencijaPojedinacnaFormComponent } from './pojedinacna/pdv-evidencija-pojedinacna-form/pdv-evidencija-pojedinacna-form.component';
import { PdvEvidencijaPojedinacnaTableComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-table.component';
import { PdvEvidencijaPojedinacnaResolver } from './pojedinacna/resolvers/pdv-evidencija-pojedinacna-resolver';
import { PdvEvidencijaZbirnaResolver } from './pojedinacna/resolvers/pdv-evidencija-zbirna-resolver';
import { PdvEvidencijaZbirnaFormComponent } from './zbirna/pdv-evidencija-zbirna-form/pdv-evidencija-zbirna-form.component';
import { PdvEvidencijaPojedinacnaIzIzlazneFaktureResolver } from './pojedinacna/resolvers/pdv-evidencija-pojedinacna-iz-izlazne-fakture-resolver';
import { PdvEvidencijaZbirnaForm39Component } from './zbirna/pdv-evidencija-zbirna-form39/pdv-evidencija-zbirna-form39.component';
import { PdvEvidencijaZbirna39Resolver } from './pojedinacna/resolvers/pdv-evidencija-zbirna39-resolver';
import { PdvEvidencijaPojedinacna39FormComponent } from './pojedinacna/pdv-evidencija-pojedinacna39-form/pdv-evidencija-pojedinacna39-form.component';
import { PdvEvidencijaPojedinacna39Resolver } from './pojedinacna/resolvers/pdv-evidencija-pojedinacna39-resolver';
import { PppdvFormComponent } from './prethodni-porez/pppdv-form/pppdv-form.component';
import { PPPDVResolver } from './prethodni-porez/resolvers/pppdv-resolver';

const routes: Routes = [
  {
    path: 'pdv-evidencije',
    data: {
      breadcrumb: 'Pdv evidencije',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PdvEvidencijaPageComponent,
      },
      //
      // Pojedinacna
      //
      {
        path: 'pojedinacne',
        data: {
          //breadcrumb: { skip: true },
          breadcrumb: 'Pojedinačne',
        },
        children: [
          {
            path: 'unos',
            data: {
              breadcrumb: 'Unos pojedinačne pdv evidencije',
            },
            resolve: {
              // formConfiguration: RacunFormConfigurationResolver,
            },
            component: PdvEvidencijaPojedinacnaFormComponent,
          },
          {
            path: 'unos39',
            data: {
              breadcrumb: 'Unos pojedinačne pdv evidencije',
            },
            component: PdvEvidencijaPojedinacna39FormComponent,
          },
          {
            path: 'unos-preko-fakture/:racunId',
            data: {
              breadcrumb: 'Unos pojedinačne pdv evidencije preko fakture',
            },
            resolve: {
              evidencija: PdvEvidencijaPojedinacnaIzIzlazneFaktureResolver,
            },
            component: PdvEvidencijaPojedinacnaFormComponent,
          },
          {
            path: '',
            data: {
              breadcrumb: '',
            },

            component: PdvEvidencijaPojedinacnaTableComponent,
          },
          {
            path: 'izmena/:id',
            data: {
              breadcrumb: {
                alias: 'izmenaPojedinacnePdvEvidencije',
              },
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaPojedinacnaResolver,
            },
            component: PdvEvidencijaPojedinacnaFormComponent,
          },
          {
            path: 'izmena39/:id',
            data: {
              breadcrumb: {
                alias: 'izmenaPojedinacnePdvEvidencije',
              },
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaPojedinacna39Resolver,
            },
            component: PdvEvidencijaPojedinacna39FormComponent,
          },
          {
            path: 'korekcija/:id',
            data: {
              breadcrumb: {
                alias: 'korigovanjePojedinacnePdvEvidencije',
              },
              isKoriguj: true,
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaPojedinacnaResolver,
            },
            component: PdvEvidencijaPojedinacnaFormComponent,
          },
          {
            path: 'korekcija39/:id',
            data: {
              breadcrumb: {
                alias: 'korigovanjePojedinacnePdvEvidencije',
              },
              isKoriguj: true,
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaPojedinacna39Resolver,
            },
            component: PdvEvidencijaPojedinacna39FormComponent,
          },
          {
            path: 'pregled-korigovane/:id',
            data: {
              breadcrumb: {
                alias: 'pregledKorigovanePojedinacnePdvEvidencije',
              },
              isPregledKorigovane: true,
            },
            resolve: {
              evidencija: PdvEvidencijaPojedinacnaResolver,
            },
            component: PdvEvidencijaPojedinacnaFormComponent,
          },
          {
            path: 'pregled-korigovane39/:id',
            data: {
              breadcrumb: {
                alias: 'pregledKorigovanePojedinacnePdvEvidencije',
              },
              isPregledKorigovane: true,
            },
            resolve: {
              evidencija: PdvEvidencijaPojedinacna39Resolver,
            },
            component: PdvEvidencijaPojedinacna39FormComponent,
          },
          // DEFAULT
          { path: '**', redirectTo: 'unos' },
        ],
      },
      //
      // Zbirna
      //
      //
      {
        path: 'zbirne',
        data: {
          //breadcrumb: { skip: true },
          breadcrumb: 'Zbirne',
        },
        children: [
          {
            path: 'unos',
            data: {
              breadcrumb: 'Unos zbirne pdv evidencije',
            },
            resolve: {
              // formConfiguration: RacunFormConfigurationResolver,
            },
            component: PdvEvidencijaZbirnaFormComponent,
          },
          {
            path: 'unos39',
            data: {
              breadcrumb: 'Unos zbirne pdv evidencije',
            },
            resolve: {
              // formConfiguration: RacunFormConfigurationResolver,
            },
            component: PdvEvidencijaZbirnaForm39Component,
          },
          {
            path: 'izmena/:id',
            data: {
              breadcrumb: {
                alias: 'izmenaZbirnePdvEvidencije',
              },
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaZbirnaResolver,
            },
            component: PdvEvidencijaZbirnaFormComponent,
          },
          {
            path: 'izmena39/:id',
            data: {
              breadcrumb: {
                alias: 'izmenaZbirnePdvEvidencije',
              },
            },
            resolve: {
              evidencija: PdvEvidencijaZbirna39Resolver,
            },
            component: PdvEvidencijaZbirnaForm39Component,
          },
          {
            path: 'korekcija/:id',
            data: {
              breadcrumb: {
                alias: 'korigovanjeZbirnePdvEvidencije',
              },
              isKoriguj: true,
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaZbirnaResolver,
            },
            component: PdvEvidencijaZbirnaFormComponent,
          },
          {
            path: 'korekcija39/:id',
            data: {
              breadcrumb: {
                alias: 'korigovanjeZbirnePdvEvidencije',
              },
              isKoriguj: true,
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PdvEvidencijaZbirna39Resolver,
            },
            component: PdvEvidencijaZbirnaForm39Component,
          },
          {
            path: 'pregled-korigovane/:id',
            data: {
              breadcrumb: {
                alias: 'pregledKorigovaneZbirnePdvEvidencije',
              },
              isPregledKorigovane: true,
            },
            resolve: {
              evidencija: PdvEvidencijaZbirnaResolver,
            },
            component: PdvEvidencijaZbirnaFormComponent,
          },
          {
            path: 'pregled-korigovane39/:id',
            data: {
              breadcrumb: {
                alias: 'pregledKorigovaneZbirnePdvEvidencije',
              },
              isPregledKorigovane: true,
            },
            resolve: {
              evidencija: PdvEvidencijaZbirna39Resolver,
            },
            component: PdvEvidencijaZbirnaForm39Component,
          },
          // DEFAULT
          { path: '**', redirectTo: 'unos' },
        ],
      },
      // PPPDV
      //
      //
      {
        path: 'pppdv',
        data: {
          //breadcrumb: { skip: true },
          breadcrumb: 'PPPDV',
        },
        children: [
          {
            path: 'unos',
            data: {
              breadcrumb: 'Unos evidencije prethodnog poreza',
            },
            resolve: {
              // formConfiguration: RacunFormConfigurationResolver,
            },
            component: PppdvFormComponent,
          },
          {
            path: 'izmena/:id',
            data: {
              breadcrumb: {
                alias: 'izmenaPojedinacnePdvEvidencije',
              },
            },
            resolve: {
              evidencija: PPPDVResolver,
            },
            component: PppdvFormComponent,
          },
          {
            path: 'korekcija/:id',
            data: {
              breadcrumb: {
                alias: 'korigovanjeEvidencijePrethodnogPoreza',
              },
              isKoriguj: true,
            },
            resolve: {
              //formConfiguration: RacunFormConfigurationResolver,
              evidencija: PPPDVResolver,
            },
            component: PppdvFormComponent,
          },
          {
            path: 'pregled-korigovane/:id',
            data: {
              breadcrumb: {
                alias: 'pregledKorigovanePPPDV',
              },
              isPregledKorigovane: true,
            },
            resolve: {
              evidencija: PPPDVResolver,
            },
            component: PppdvFormComponent,
          },
          // DEFAULT
          { path: '**', redirectTo: 'unos' },
        ],
      },
      // DEFAULT
      { path: '**', redirectTo: '' },
    ],
  },
  { path: '**', redirectTo: 'pdv-evidencije' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PdvEvidencijaRoutingModule {}
