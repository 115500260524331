<div class="kodit-card-tab">
  <p-tabView
    [(activeIndex)]="activeIndex"
    (activeIndexChange)="handleIndexChange($event)"
  >
    <p-tabPanel header="Fakture"></p-tabPanel>
    <p-tabPanel header="Avansni računi"></p-tabPanel>
    <p-tabPanel header="Dokumenti o smanjenju"></p-tabPanel>
    <p-tabPanel header="Dokumenti o povećanju"></p-tabPanel>
    <p-tabPanel header="Kompenzacije" *ngIf="!hasBesplatniPaket"></p-tabPanel>
    <p-tabPanel header="SEF"></p-tabPanel>
  </p-tabView>

  <div [ngSwitch]="activeIndex">
    <div *ngSwitchCase="0">
      <div class="p-mb-3">
        <kodit-racun-summary
          label1="Dugovanja"
          label2="Plaćanja"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
        ></kodit-racun-summary>
      </div>
      <kodit-racun-table [tipRacuna]="tipUlaznaFaktura"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="1">
      <kodit-racun-table [tipRacuna]="tipAvansniRacun"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="2">
      <div class="p-mb-3">
        <kodit-racun-summary
          label1="Osnovica"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
          jeIzmenaOsnovice="true"
        ></kodit-racun-summary>
      </div>
      <kodit-racun-table [tipRacuna]="tipOdobrenje"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="3">
      <div class="p-mb-3">
        <kodit-racun-summary
          label1="Osnovica"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
          jeIzmenaOsnovice="true"
        ></kodit-racun-summary>
      </div>
      <kodit-racun-table [tipRacuna]="tipZaduzenje"></kodit-racun-table>
    </div>
    <div *ngIf="hasBesplatniPaket">
      <div *ngSwitchCase="4">
        <kodit-neobradjen-sef-racun-table></kodit-neobradjen-sef-racun-table>
      </div>
    </div>
    <div *ngIf="!hasBesplatniPaket">
      <div *ngSwitchCase="4">
        <kodit-kompenzacija-table
          [tipRacuna]="tipZaduzenje"
        ></kodit-kompenzacija-table>
      </div>
      <div *ngSwitchCase="5">
        <kodit-neobradjen-sef-racun-table></kodit-neobradjen-sef-racun-table>
      </div>
    </div>
  </div>
</div>
