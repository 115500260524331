import { Injectable } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  IKompenzacijaDto,
  IKompenzacionaObavezaDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { StrankaRacunaFormService } from './stranka-racuna-form.service';

@Injectable({
  providedIn: 'root',
})
export class KompenzacijaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _strankaRacunaFormService: StrankaRacunaFormService
  ) {}

  public GetKompenzacijaFormGroup(
    vrstaRacuna: VrstaRacuna,
    model?: IKompenzacijaDto
  ): FormGroupTypeSafe<IKompenzacijaDto> {
    return this._fb.group<IKompenzacijaDto>({
      kompenzacijaId: new FormControl(model?.kompenzacijaId),
      broj: new FormControl(model?.broj),
      tekuciRacunStranke: new FormControl(model?.tekuciRacunStranke),
      strankaId: new FormControl(model?.stranka.id),
      stranka: this._strankaRacunaFormService.GetStrankaRacunaFormGroup(
        model?.stranka
      ),
      napomena : new FormControl(model?.napomena),
      tekuciRacunId: new FormControl(model?.tekuciRacunId),
      razlika: new FormControl(model?.razlika),
      vrsta: new FormControl(vrstaRacuna),
      obavezePremaPoveriocu: this.GetObavezeFormArray(
        model?.obavezePremaPoveriocu
      ),
      obavezePremaDuzniku: this.GetObavezeFormArray(model?.obavezePremaDuzniku),
    });
  }

  public GetObavezeFormArray(obaveze?: IKompenzacionaObavezaDto[]): FormArray {
    if (!obaveze || obaveze.length === 0) {
      return this._fb.array([], {
        validators: [Validators.required],
      });
    }
    const result: FormArray = this._fb.array([]);

    obaveze.forEach((obaveza) => {
      result.push(this.GetObavezeFormGroup(obaveza));
    });

    return result;
  }

  public GetObavezeFormGroup(
    model?: IKompenzacionaObavezaDto
  ): FormGroupTypeSafe<IKompenzacionaObavezaDto> {
    return this._fb.group<IKompenzacionaObavezaDto>({
      kompenzacionaObavezaId: new FormControl(model?.kompenzacionaObavezaId),
      racunId: new FormControl(model?.racunId, {
        validators: [Validators.required],
      }),
      broj: new FormControl(model?.broj, {
        validators: [Validators.required],
      }),
      originalanBroj : new FormControl(model?.originalanBroj),
      poverilacId: new FormControl(model?.poverilacId),
      duznikId: new FormControl(model?.duznikId),
      datumPrometa: new FormControl(model?.datumPrometa),
      datumValute: new FormControl(model?.datumValute),
      iznos: new FormControl(model?.iznos),
      ukupanIznosRacuna: new FormControl(model?.ukupanIznosRacuna)
    });
  }
}
