<div class="kodit-card-tab">
  <p-tabView
    [(activeIndex)]="activeIndex"
    (activeIndexChange)="handleIndexChange($event)"
  >
    <p-tabPanel #Fakture header="Fakture" *ngIf="shouldDisplayFakture"></p-tabPanel>
    <p-tabPanel #Avansni header="Avansni računi" *ngIf="shouldDisplayAvansne"></p-tabPanel>
    <p-tabPanel #DOS header="Dokumenti o smanjenju" *ngIf="shouldDisplayKO"></p-tabPanel>
    <p-tabPanel #KZ header="Dokumenti o povećanju" *ngIf="shouldDisplayKZ"></p-tabPanel>
    <p-tabPanel #KOMP header="Kompenzacije" *ngIf="shouldDisplayKomp"></p-tabPanel>
  </p-tabView>

  <div [ngSwitch]="activeIndex">
    <div *ngSwitchCase="0">
      <div class="p-mb-3 p-grid p-flex-column p-flex-lg-row">
        <kodit-racun-summary
          class="p-xl-12 p-lg-12"
          label1="Promet"
          label2="Uplate"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
        ></kodit-racun-summary>
        <kodit-racun-van-valute-summary
          class="p-xl-12 p-lg-12"
        ></kodit-racun-van-valute-summary>
      </div>

      <kodit-racun-table [tipRacuna]="tipIzlaznaFaktura"
      joyrideStep="test1"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="1">
      <div class="p-mb-3">
        <kodit-racun-summary
          label1=""
          label2="Uplate"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
        ></kodit-racun-summary>
      </div>
      <kodit-racun-table [tipRacuna]="tipAvansniRacun"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="2">
      <div class="p-mb-3">
        <kodit-racun-summary
          class="p-xl-12 p-lg-12"
          label1="Osnovica"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
          jeIzmenaOsnovice="true"
        ></kodit-racun-summary>
      </div>
      <kodit-racun-table [tipRacuna]="tipOdobrenje"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="3">
      <div class="p-mb-3">
        <kodit-racun-summary
          class="p-xl-12 p-lg-12"
          label1="Osnovica"
          label3="Ukupno PDV 10%"
          label4="Ukupno PDV 20%"
          jeIzmenaOsnovice="true"
        ></kodit-racun-summary>
      </div>
      <kodit-racun-table [tipRacuna]="tipZaduzenje"></kodit-racun-table>
    </div>
    <div *ngSwitchCase="4">
      <kodit-kompenzacija-table></kodit-kompenzacija-table>
    </div>
  </div>
</div>
