<div
  class="p-d-flex p-flex-column p-flex-md-row p-ai-center p-fluid p-flex-wrap header-box"
>
  <div class="p-field max-width">
    <label for="pretraga-racun" kodit-form-label>Povezani račun</label>
    <span class="p-input-icon-left pretraga-box">
      <i class="fas fa-search"></i>
      <p-autoComplete
        inputId="pretraga-racun"
        #oznakaInput
        [suggestions]="racuniSuggestions"
        field="naziv"
        placeholder="Broj računa..."
        pTooltip="Unesi broj računa za pretragu"
        (completeMethod)="searchRacuni($event)"
        (onSelect)="onRacunSelect($event)"
        appendTo="body"
      >
        <ng-template let-racun pTemplate="item">
          <div>
            <div>{{ racun.tipStr }}</div>
            <div class="p-text-bold">{{ racun.label }}</div>
          </div>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

<div class="p-d-flex p-flex-wrap kodit-chip-container" card-body>
  <ng-container>
    <div
      class="p-mr-2 p-mb-2 my-p-chip"
      *ngFor="let racun of racuniToAdd; index as i"
    >
      <ng-container>

        <img
            src="assets/icons/document.svg"
            width="14"
            height="14"
            alt="icon-image"
        />
        <div>
          <div class="my-p-chip-text">
            {{ racun.label }}
          </div>
        </div>
        <span
          class="far fa-times-circle my-p-chip-remove-icon"
          pTooltip="Ukloni vezani račun"
          tooltipPosition="bottom"
          (click)="onRemoveVezaniRacun(i)"
        ></span>
      </ng-container>
    </div>
  </ng-container>
</div>
