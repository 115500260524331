import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
    IStornoIzlazniRacunCommand,
    RacuniClient,
    SefClient,
    StornoIzlazniRacunCommand,
} from '@kodit/core/data-api';
import {FormHelper} from '@kodit/core/form-definitions';
import {AlertService, SharedService} from '@kodit/core/services';
import {DynamicDialogConfig, DynamicDialogRef} from '@kodit/core/shared-ui';
import {
    FormBuilderTypeSafe,
    FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {Subscription} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'kodit-storno-izlazni-racun-form',
    templateUrl: './storno-izlazni-racun-form.component.html',
    styleUrls: ['./storno-izlazni-racun-form.component.scss'],
})
export class StornoIzlazniRacunFormComponent implements OnInit, OnDestroy {
    private _subs: Subscription = new Subscription();
    form: FormGroupTypeSafe<IStornoIzlazniRacunCommand>;
    brojRacuna: string;
    pdfInnerHtml: SafeHtml;

    constructor(
        private _fs: FormBuilderTypeSafe,
        private _client: SefClient,
        private _alertService: AlertService,
        private _dialogRef: DynamicDialogRef,
        private _dialogConfig: DynamicDialogConfig,
        private _formHelper: FormHelper,
        private _racuniClient: RacuniClient,
        private _sanitizier: DomSanitizer,
        private _sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        // subscribe on dialog save button
        this._subs.add(
            this._dialogRef.onSubmit.subscribe(() => {
                this.handleStorno();
            })
        );
        this.form = this._fs.group<IStornoIzlazniRacunCommand>({
            racunId: new FormControl(this._dialogConfig.data.racunId, {
                validators: Validators.required,
            }),
            fromId: new FormControl('', {
              validators: Validators.required,
            }),
            komentar: new FormControl(null, {
                validators: [Validators.required, Validators.maxLength(128)],
            }),
            stornoBroj: new FormControl(null, {
                validators: [Validators.maxLength(32)],
            }),
            to: new FormControl(null, {
                validators: [Validators.required],
            }),
            attachments: new FormControl(null),
        });
        this.brojRacuna = this._dialogConfig.data.brojRacuna;

        this._getIzjavaZaOdbitakPdvPdf();
    }

    private _getIzjavaZaOdbitakPdvPdf() {
        this._subs.add(
            this._racuniClient
                .getIzjavaZaOdbitakPdvPdf(this._dialogConfig.data.racunId)
                .subscribe((result) => {
                    this._setPdf(result.data);
                })
        );
    }

    private _setPdf(blob: Blob) {
        const blobUrl = URL.createObjectURL(blob);
        this.pdfInnerHtml = this._sanitizier.bypassSecurityTrustHtml(
            "<object  data='" +
            blobUrl +
            "' type='application/pdf' class='embed-responsive-item' >" +
            'Object ' +
            blobUrl +
            ' failed' +
            '</object>'
        );
    }

    handleStorno() {
        if (!this.form.valid) {
            this._formHelper.invalidateForm(this.form);
            return;
        }

        // everything is ok, let's send it
        this._sharedService.displayLoadingScreen(true, "Storniranje računa u toku");
        this._subs.add(
            this._client
                .stornoIzlazniRacun(this.form.value as StornoIzlazniRacunCommand)
                .subscribe((res) => {
                    if (res.succeeded) {
                        this._alertService.addSuccessMsg(res.data);
                    } else {
                        this._alertService.addFailedMsg(res.messages[0]);
                    }
                    this._dialogRef.close(true);
                })
        );
    }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }
}
