import { Component, DebugElement, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import {
  IKompenzacijaDto,
  IKompenzacionaObavezaDto,
  IRacunKompenzacijaDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { KompenzacijaFormService } from '@kodit/core/form-definitions';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { KompenzacijaService } from '../../kompezacija.service';

@Component({
  selector: 'kodit-obaveze-kompenzacija-table',
  templateUrl: './obaveze-kompenzacija-table.component.html',
  styleUrls: ['./obaveze-kompenzacija-table.component.scss'],
})
export class ObavezeKompenzacijaTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  obavezeKompenzacije: FormArray;
  selectedObaveze: IKompenzacionaObavezaDto[] = [];
  kompenzacijaForm: FormGroupTypeSafe<IKompenzacijaDto>;
  currentFormArrayName: string;

  /** props */
  racunKompenzacijaSelected: IRacunKompenzacijaDto = {};
  obavezeDuznika: number = 0;
  obavezePoverioca: number = 0;
  vrstaRacunZaPretragu: VrstaRacuna;

  /** I/O */
  @Input() jePremaPoveriocu: boolean;
  @Input() jeMojaFirmaPoverilac: boolean;
  constructor(
    private _kompenzacijaFormService: KompenzacijaFormService,
    private _service: KompenzacijaService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._service.getForm.subscribe((res) => {
        this.kompenzacijaForm = res;
      })
    );

    if (this.jePremaPoveriocu) {
      this.obavezeKompenzacije = this._service.getObavezePremaPoveriocuArray;
      this.currentFormArrayName = 'obavezePremaPoveriocu';
    } else {
      this.obavezeKompenzacije = this._service.getObavezePremaDuznikuArray;
      this.currentFormArrayName = 'obavezePremaDuzniku';
    }

    this.addNewObaveza();

    if (
      this.jePremaPoveriocu === undefined &&
      this.jeMojaFirmaPoverilac === undefined
    ) {
      this.vrstaRacunZaPretragu = VrstaRacuna.ULAZNI;
    } else {
      this.vrstaRacunZaPretragu =
        this.jePremaPoveriocu && this.jeMojaFirmaPoverilac
          ? VrstaRacuna.ULAZNI
          : VrstaRacuna.IZLAZNI;
    }

  }

  handleRacunKompenzacijaChange(
    racunKompenzacija: IRacunKompenzacijaDto,
    rowIndex: number
  ) {
    this.racunKompenzacijaSelected = racunKompenzacija;
    this.obavezeKompenzacije.at(rowIndex).patchValue(racunKompenzacija);
    this._obracunajRazliku();
  }

  private _obracunajRazliku() {
    this.obavezeDuznika = 0;
    this.obavezePoverioca = 0;
    this.kompenzacijaForm.controls.obavezePremaDuzniku.value.forEach((x) => {
      this.obavezeDuznika += x.iznos;
    });
    this.kompenzacijaForm.controls.obavezePremaPoveriocu.value.forEach((x) => {
      this.obavezePoverioca += x.iznos;
    });
    this.kompenzacijaForm.controls.razlika.setValue(
      this.obavezePoverioca - this.obavezeDuznika
    );
  }

  deleteObavezu(rowIndex: number): void {
    this.obavezeKompenzacije.removeAt(rowIndex);
    this._obracunajRazliku();

    if (this.obavezeKompenzacije.length === 0) {
      this.addNewObaveza();
    }
    this._service.izbaciIzNizaStarih(rowIndex, this.currentFormArrayName);
  }

  handleRacunKompenzacijaClear() {}

  addNewObaveza() {
    this.obavezeKompenzacije.push(
      this._kompenzacijaFormService.GetObavezeFormGroup()
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
