import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IRacunInfoDto,
  ResultOfListOfVezaniRacunDto,
  StatusRacuna,
  TipRacuna,
  VezaniRacunDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { LocationService, SharedService } from '@kodit/core/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RacunInfoService } from '../racun-info/racun-info.service';
import { DynamicDialogConfig, DynamicDialogService } from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { AddPovezaniRacunFormComponent } from './add-povezani-racun-form/add-povezani-racun-form.component';


@Component({
  selector: 'kodit-osnovne-informacije',
  templateUrl: './osnovne-informacije.component.html',
  styleUrls: ['./osnovne-informacije.component.scss'],
})
export class OsnovneInformacijeComponent implements OnInit, OnDestroy {
  private _getSub = new Subscription();
  
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  racunInfoDto: IRacunInfoDto;
  naziv: string;
  jeUlazniRacun: boolean;
  razlogLabel: string;
  showAddPovezaniRacuni: boolean;
  jeAvansniZaPovezivanje: boolean;
  jeFakturaZaPovezivanje: boolean;
  jePredracunZaPovezivanje: boolean;
  jeOtpremnicaZaPovezivanje: boolean;
  buttonLabel : string;
  vezaniRacuniDto : VezaniRacunDto[] = [];

  constructor(
    private _location: LocationService,
    private _sharedService: SharedService,
    private _racunInfoService: RacunInfoService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    this._getSub.add(
      this._racunInfoService.getRacunInfoDto.subscribe((res) => {
        if (res) {
          this.racunInfoDto = res;
          this.jeUlazniRacun = this.racunInfoDto.vrsta === VrstaRacuna.ULAZNI;
          this.naziv = this._sharedService.racunNames[this.racunInfoDto.tip];
          this._setRazlogText();
          this.jeAvansniZaPovezivanje = this.racunInfoDto.tip == TipRacuna.AVANSNI_RACUN && this.racunInfoDto.vrsta == VrstaRacuna.IZLAZNI;
          this.jeFakturaZaPovezivanje = this.racunInfoDto.tip == TipRacuna.IZLAZNA_FAKTURA;
          this.jeOtpremnicaZaPovezivanje =  this.racunInfoDto.tip == TipRacuna.OTPREMNICA;
          this.jePredracunZaPovezivanje = this.racunInfoDto.tip == TipRacuna.PREDRACUN;

          this.showAddPovezaniRacuni = 
            (this.jeAvansniZaPovezivanje || this.jeFakturaZaPovezivanje || this.jeOtpremnicaZaPovezivanje || this.jePredracunZaPovezivanje) 
            &&  this.racunInfoDto.statusDto.status == StatusRacuna.NACRT;

          this.buttonLabel = this.jeAvansniZaPovezivanje ? 'Veži za predračun' :
            this.jeFakturaZaPovezivanje ? 'Veži za otpremnicu' :
            this.jeOtpremnicaZaPovezivanje ? 'Veži za fakturu' :
            'Veži za avansni račun';
        }
      })
    );
  }

  goToStranka() {
    this._location.routeToStrankaKartica(
      this.racunInfoDto.strankaId,
      this.racunInfoDto.tipStranke
    );
  }

  private _setRazlogText() {
    switch (this.racunInfoDto.statusDto.status) {
      case StatusRacuna.OTKAZANO:
        this.razlogLabel = 'otkazivanja';
        break;
      case StatusRacuna.ODBIJENO:
        this.razlogLabel = 'odbijanja';
        break;
      case StatusRacuna.STORNIRANO:
        this.razlogLabel = 'storniranja';
        break;
      default:
        break;
    }
  }

  openAddPovezaniRacunDialog() {
    this.dialogConfig.data = { racun: this.racunInfoDto };
    this.dialogConfig.header = 'Povezivanje računa';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: 'fas fa-plus'
    } as any;

    const ref = this._dialogService.open(
      AddPovezaniRacunFormComponent,
      this.dialogConfig
    );
  }

  ngOnDestroy(): void {
    this._racunInfoService.setVezaniRacuni = [];
    this._getSub.unsubscribe();
  }
}
