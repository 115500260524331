import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RacunSaldoDto } from '@kodit/core/data-api';
import { SummaryService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-racun-summary',
  templateUrl: './racun-summary.component.html',
  styleUrls: ['./racun-summary.component.scss'],
})
export class RacunSummaryComponent implements OnInit, OnDestroy {
  /** I/O */
  @Input() label1: string = '';
  @Input() label2: string = '';
  @Input() label3: string = '';
  @Input() label4: string = '';
  @Input() jeAvans = false;
  @Input() jeIzmenaOsnovice: boolean = false;
  racuniSaldo: RacunSaldoDto;
  private _subs: Subscription = new Subscription();

  constructor(private _service: SummaryService) {
    this.racuniSaldo = new RacunSaldoDto();
  }

  ngOnInit(): void {
    this._subs.add(
      this._service.getRacunSaldo.subscribe((data) => {
        this.racuniSaldo = data;
        if(this.jeIzmenaOsnovice)
        {
          this.racuniSaldo.iznosOsnovica = Math.abs(this.racuniSaldo.iznosOsnovica);
          this.racuniSaldo.iznosPDV10 = Math.abs(this.racuniSaldo.iznosPDV10);
          this.racuniSaldo.iznosPDV20 = Math.abs(this.racuniSaldo.iznosPDV20);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
