<div class="p-mb-3 p-fluid" *ngIf="jeProcreditBanka">
  <p-message
    severity="warn"
    text="Poštovani, sa ProCredit bankom je bilo problema oko uvoza izvoda. Javite nam se kako bismo izmenili pojedine podatke sa izvoda i omogućili Vam da pravite avansne račune preko uplata/isplata."
  ></p-message>
</div>

<div class="p-d-flex p-flex-column p-flex-md-row header-box">
  <div card class="k-card info-card">
    <div class="p-d-flex p-jc-between _header">
      <div class="p-text-left _title">
        Izvod broj: <span>{{ izvod.broj }}</span>
      </div>
      <div class="p-text-left _subtitle">
        {{ izvod.datumIzdavanja }}
      </div>
    </div>
    <kodit-divider></kodit-divider>

    <div class="p-d-flex p-jc-between p-flex-wrap p-mb-2 gap-12">
      <div class="p-label">Tekući račun:</div>
      <div class="p-text-left">{{ izvod.brojTekucegRacuna }}</div>
    </div>
    <div class="p-d-flex p-jc-between p-flex-wrap p-mb-2 gap-12">
      <div class="p-label">Banka:</div>
      <div class="p-text-left">{{ izvod.nazivBanke }}</div>
    </div>
    <div class="p-d-flex p-jc-between p-flex-wrap p-mb-2 gap-12">
      <div class="p-label">Status:</div>
      <div
        [ngClass]="
          'p-text-left info-badge status-izvoda-' +
          izvod.statusDto.statusBadgeStr
        "
      >
        {{ izvod.statusDto.statusStr.toUpperCase() }}
      </div>
    </div>
  </div>

  <div
    card
    class="k-card promet-card p-d-flex p-jc-between p-ai-center p-flex-wrap"
  >
    <div class="p-d-flex p-flex-column promet-item">
      <div class="p-pr-2 p-label">Početno stanje:</div>
      <div class="p-text-left">
        {{ izvod.stanjePrethodnogIzvoda | currencyNumber: izvod.valutaText }}
      </div>
    </div>
    <div class="p-d-flex p-flex-column promet-item">
      <div class="p-pr-2 p-label">Novo stanje:</div>
      <div class="p-text-left">
        {{ izvod.stanjeTrenutnogIzvoda | currencyNumber: izvod.valutaText }}
      </div>
    </div>
    <div class="p-d-flex p-flex-column promet-item">
      <div class="p-pr-2 p-label">Promena:</div>
      <div
        [className]="
          izvod.promenaStanja > 0 ? 'promena-pozitivna' : 'promena-negativna'
        "
      >
        {{ izvod.promenaStanja | currencyNumber: izvod.valutaText }}
      </div>
    </div>
  </div>
</div>

<div class="p-d-flex p-jc-between p-mt-5">
  <div class="p-d-flex">
    <div>
      <button
        *ngIf="izvod.prethodniIzvod"
        pButton
        type="button"
        [label]="izvod.prethodniIzvod.text"
        class="p-button-link"
        (click)="onSaltanjeIzvoda(izvod.prethodniIzvod)"
      ></button>
    </div>

    <div>
      <button
        *ngIf="izvod.sledeciIzvod"
        pButton
        type="button"
        [label]="izvod.sledeciIzvod.text"
        class="p-button-link"
        (click)="onSaltanjeIzvoda(izvod.sledeciIzvod)"
      ></button>
    </div>
  </div>
  <div class="p-d-flex">
    <div *ngIf="tabeleSelectedOption === 1" class="_subtitle p-mr-3 p-mt-2">
      <b>Ukupno potražuje:</b>
      {{ izvod.ukupnoPotrazuje | currencyNumber: izvod.valutaText }}
    </div>

    <div *ngIf="tabeleSelectedOption === 2" class="_subtitle p-mr-3 p-mt-2">
      <b>Ukupno duguje:</b>
      {{ izvod.ukupnoDuguje | currencyNumber: izvod.valutaText }}
    </div>
    <div>
      <p-selectButton
        [options]="tabeleOptions"
        [(ngModel)]="tabeleSelectedOption"
      ></p-selectButton>
    </div>
  </div>
</div>

<ng-container *ngIf="tabeleSelectedOption === 1">
  <div card class="k-card p-mt-5">
    <kodit-uplata-table   (vrednostUplataPromena)="pratiVrednostUplata($event)" [uplateTable]="izvod.uplateTable" [jePrekoIzvoda]="true" [jeRucnoUneto]="jeRucnoUneto"></kodit-uplata-table>
  </div>
</ng-container>

<ng-container *ngIf="tabeleSelectedOption === 2">
  <div card class="k-card p-mt-5">
    <kodit-isplata-table
      (vrednostIsplataPromena)="pratiVrednostIsplata($event)"
      [isplateTable]="izvod.isplateTable"
      [jePrekoIzvoda]="true"
      [jeRucnoUneto]="jeRucnoUneto"
    ></kodit-isplata-table>
  </div>
</ng-container>
