import { Injectable } from '@angular/core';
import { PPPdvForm, PPPdvService } from './pppdv.service';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  IPdvEvidencijaPojedinacna39Dto,
  IPPPDVEvidencijaDto,
  PdvEvidencijaOsnovOdabiraDokumenta, PdvEvidencijaStatus,
  PdvEvidencijaTipDokumenta, PdvEvidencijaTipPovezaneFakture, PdvEvidencijaTipPovezanogInternog, PdvPeriod, VrstaRacuna
} from '@kodit/core/data-api';
import { Pdv39Form } from '../pojedinacna/pdv-evidencija-pojedinacna.service';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PPPDVFormService {
  /* Props */
  private _form!: PPPdvForm;
  private _isInitialized: boolean;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: PPPdvService,
  ) {
    this._isInitialized = false;
  }

  getForm(
    model: IPPPDVEvidencijaDto,
    shouldDisableControls: boolean
  ): PPPdvForm {
    if (!this._isInitialized) {
      this._init(model, shouldDisableControls);
      this._isInitialized = true;
    }
    return this._form;
  }

  private _init(
    model: IPPPDVEvidencijaDto,
    shouldDisableComponents: boolean
  ){
    this._form = this._fb.group<IPPPDVEvidencijaDto>({
      id: new FormControl(model?.id),
      sefId: new FormControl(model?.sefId ?? 0),
      broj: new FormControl(
        {
          value: model?.broj,
          disabled: shouldDisableComponents,
        },
        {
          validators: [Validators.required, Validators.maxLength(32)],
        }
      ),
      godina: new FormControl(model?.godina ?? new Date().getFullYear(), {
        validators: [Validators.required],
      }),
      datumEvidentiranja: new FormControl(
        model?.datumEvidentiranja ?? new Date()
      ),
      status: new FormControl(model?.status ?? PdvEvidencijaStatus.U_PRIPREMI),
      period: new FormControl(model?.period ?? PdvPeriod.JANUAR),

      //1.1
      eF20Osnovica11: new FormControl(model?.eF20Osnovica11),
      eF20Pdv11: new FormControl(model?.eF20Pdv11),
      eF10Osnovica11: new FormControl(model?.eF10Osnovica11),
      eF10Pdv11: new FormControl(model?.eF10Pdv11),

      fiskalni20Osnovica11: new FormControl(model?.fiskalni20Osnovica11),
      fiskalni20Pdv11: new FormControl(model?.fiskalni20Pdv11),
      fiskalni10Osnovica11: new FormControl(model?.fiskalni10Osnovica11),
      fiskalni10Pdv11: new FormControl(model?.fiskalni10Pdv11),

      drugi20Osnovica11: new FormControl(model?.drugi20Osnovica11),
      drugi20Pdv11: new FormControl(model?.drugi20Pdv11),
      drugi10Osnovica11: new FormControl(model?.drugi10Osnovica11),
      drugi10Pdv11: new FormControl(model?.drugi10Pdv11),

      ukupno20Osnovica11: new FormControl(model?.ukupno20Osnovica11),
      ukupno20Pdv11: new FormControl(model?.ukupno20Pdv11),
      ukupno10Osnovica11: new FormControl(model?.ukupno10Osnovica11),
      ukupno10Pdv11: new FormControl(model?.ukupno10Pdv11),

      odbitak20Pdv11: new FormControl(model?.odbitak20Pdv11),
      odbitak10Pdv11: new FormControl(model?.odbitak10Pdv11),


      //1.2
      eF20Osnovica12: new FormControl(model?.eF20Osnovica12),
      eF20Pdv12: new FormControl(model?.eF20Pdv12),
      eF10Osnovica12: new FormControl(model?.eF10Osnovica12),
      eF10Pdv12: new FormControl(model?.eF10Pdv12),

      fiskalni20Osnovica12: new FormControl(model?.fiskalni20Osnovica12),
      fiskalni20Pdv12: new FormControl(model?.fiskalni20Pdv12),
      fiskalni10Osnovica12: new FormControl(model?.fiskalni10Osnovica12),
      fiskalni10Pdv12: new FormControl(model?.fiskalni10Pdv12),

      drugi20Osnovica12: new FormControl(model?.drugi20Osnovica12),
      drugi20Pdv12: new FormControl(model?.drugi20Pdv12),
      drugi10Osnovica12: new FormControl(model?.drugi10Osnovica12),
      drugi10Pdv12: new FormControl(model?.drugi10Pdv12),

      ukupno20Osnovica12: new FormControl(model?.ukupno20Osnovica12),
      ukupno20Pdv12: new FormControl(model?.ukupno20Pdv12),
      ukupno10Osnovica12: new FormControl(model?.ukupno10Osnovica12),
      ukupno10Pdv12: new FormControl(model?.ukupno10Pdv12),

      odbitak20Pdv12: new FormControl(model?.odbitak20Pdv12),
      odbitak10Pdv12: new FormControl(model?.odbitak10Pdv12),


      //1.3
      eF20Osnovica13: new FormControl(model?.eF20Osnovica13),
      eF20Pdv13: new FormControl(model?.eF20Pdv13),
      eF10Osnovica13: new FormControl(model?.eF10Osnovica13),
      eF10Pdv13: new FormControl(model?.eF10Pdv13),

      fiskalni20Osnovica13: new FormControl(model?.fiskalni20Osnovica13),
      fiskalni20Pdv13: new FormControl(model?.fiskalni20Pdv13),
      fiskalni10Osnovica13: new FormControl(model?.fiskalni10Osnovica13),
      fiskalni10Pdv13: new FormControl(model?.fiskalni10Pdv13),

      drugi20Osnovica13: new FormControl(model?.drugi20Osnovica13),
      drugi20Pdv13: new FormControl(model?.drugi20Pdv13),
      drugi10Osnovica13: new FormControl(model?.drugi10Osnovica13),
      drugi10Pdv13: new FormControl(model?.drugi10Pdv13),

      ukupno20Osnovica13: new FormControl(model?.ukupno20Osnovica13),
      ukupno20Pdv13: new FormControl(model?.ukupno20Pdv13),
      ukupno10Osnovica13: new FormControl(model?.ukupno10Osnovica13),
      ukupno10Pdv13: new FormControl(model?.ukupno10Pdv13),

      odbitak20Pdv13: new FormControl(model?.odbitak20Pdv13),
      odbitak10Pdv13: new FormControl(model?.odbitak10Pdv13),


      //1.4
      eF20Osnovica14: new FormControl(model?.eF20Osnovica14),
      eF20Pdv14: new FormControl(model?.eF20Pdv14),
      eF10Osnovica14: new FormControl(model?.eF10Osnovica14),
      eF10Pdv14: new FormControl(model?.eF10Pdv14),

      fiskalni20Osnovica14: new FormControl(model?.fiskalni20Osnovica14),
      fiskalni20Pdv14: new FormControl(model?.fiskalni20Pdv14),
      fiskalni10Osnovica14: new FormControl(model?.fiskalni10Osnovica14),
      fiskalni10Pdv14: new FormControl(model?.fiskalni10Pdv14),

      drugi20Osnovica14: new FormControl(model?.drugi20Osnovica14),
      drugi20Pdv14: new FormControl(model?.drugi20Pdv14),
      drugi10Osnovica14: new FormControl(model?.drugi10Osnovica14),
      drugi10Pdv14: new FormControl(model?.drugi10Pdv14),

      drugaDok20Osnovica14: new FormControl(model?.drugaDok20Osnovica14),
      drugaDok20Pdv14: new FormControl(model?.drugaDok20Pdv14),
      drugaDok10Osnovica14: new FormControl(model?.drugaDok10Osnovica14),
      drugaDok10Pdv14: new FormControl(model?.drugaDok10Pdv14),

      ukupno20Osnovica14: new FormControl(model?.ukupno20Osnovica14),
      ukupno20Pdv14: new FormControl(model?.ukupno20Pdv14),
      ukupno10Osnovica14: new FormControl(model?.ukupno10Osnovica14),
      ukupno10Pdv14: new FormControl(model?.ukupno10Pdv14),


      //1.5
      eF20Osnovica15: new FormControl(model?.eF20Osnovica15),
      eF20Pdv15: new FormControl(model?.eF20Pdv15),
      eF10Osnovica15: new FormControl(model?.eF10Osnovica15),
      eF10Pdv15: new FormControl(model?.eF10Pdv15),

      fiskalni20Osnovica15: new FormControl(model?.fiskalni20Osnovica15),
      fiskalni20Pdv15: new FormControl(model?.fiskalni20Pdv15),
      fiskalni10Osnovica15: new FormControl(model?.fiskalni10Osnovica15),
      fiskalni10Pdv15: new FormControl(model?.fiskalni10Pdv15),

      drugi20Osnovica15: new FormControl(model?.drugi20Osnovica15),
      drugi20Pdv15: new FormControl(model?.drugi20Pdv15),
      drugi10Osnovica15: new FormControl(model?.drugi10Osnovica15),
      drugi10Pdv15: new FormControl(model?.drugi10Pdv15),

      ukupno20Osnovica15: new FormControl(model?.ukupno20Osnovica15),
      ukupno20Pdv15: new FormControl(model?.ukupno20Pdv15),
      ukupno10Osnovica15: new FormControl(model?.ukupno10Osnovica15),
      ukupno10Pdv15: new FormControl(model?.ukupno10Pdv15),

      odbitak20Pdv15: new FormControl(model?.odbitak20Pdv15),
      odbitak10Pdv15: new FormControl(model?.odbitak10Pdv15),


      //1.6
      eF20Osnovica16: new FormControl(model?.eF20Osnovica16),
      eF20Pdv16: new FormControl(model?.eF20Pdv16),
      eF10Osnovica16: new FormControl(model?.eF10Osnovica16),
      eF10Pdv16: new FormControl(model?.eF10Pdv16),

      fiskalni20Osnovica16: new FormControl(model?.fiskalni20Osnovica16),
      fiskalni20Pdv16: new FormControl(model?.fiskalni20Pdv16),
      fiskalni10Osnovica16: new FormControl(model?.fiskalni10Osnovica16),
      fiskalni10Pdv16: new FormControl(model?.fiskalni10Pdv16),

      drugi20Osnovica16: new FormControl(model?.drugi20Osnovica16),
      drugi20Pdv16: new FormControl(model?.drugi20Pdv16),
      drugi10Osnovica16: new FormControl(model?.drugi10Osnovica16),
      drugi10Pdv16: new FormControl(model?.drugi10Pdv16),

      drugaDok20Osnovica16: new FormControl(model?.drugaDok20Osnovica16),
      drugaDok20Pdv16: new FormControl(model?.drugaDok20Pdv16),
      drugaDok10Osnovica16: new FormControl(model?.drugaDok10Osnovica16),
      drugaDok10Pdv16: new FormControl(model?.drugaDok10Pdv16),

      ukupno20Osnovica16: new FormControl(model?.ukupno20Osnovica16),
      ukupno20Pdv16: new FormControl(model?.ukupno20Pdv16),
      ukupno10Osnovica16: new FormControl(model?.ukupno10Osnovica16),
      ukupno10Pdv16: new FormControl(model?.ukupno10Pdv16),


      //2.1
      irS20Osnovica21: new FormControl(model?.irS20Osnovica21),
      irS20Pdv21: new FormControl(model?.irS20Pdv21),
      irS10Osnovica21: new FormControl(model?.irS10Osnovica21),
      irS10Pdv21: new FormControl(model?.irS10Pdv21),

      iR20Osnovica21: new FormControl(model?.iR20Osnovica21),
      iR20Pdv21: new FormControl(model?.iR20Pdv21),
      iR10Osnovica21: new FormControl(model?.iR10Osnovica21),
      iR10Pdv21: new FormControl(model?.iR10Pdv21),

      ukupno20Osnovica21: new FormControl(model?.ukupno20Osnovica21),
      ukupno20Pdv21: new FormControl(model?.ukupno20Pdv21),
      ukupno10Osnovica21: new FormControl(model?.ukupno10Osnovica21),
      ukupno10Pdv21: new FormControl(model?.ukupno10Pdv21),

      odbitak20Pdv21: new FormControl(model?.odbitak20Pdv21),
      odbitak10Pdv21: new FormControl(model?.odbitak10Pdv21),


      //2.2
      irS20Osnovica22: new FormControl(model?.irS20Osnovica22),
      irS20Pdv22: new FormControl(model?.irS20Pdv22),
      irS10Osnovica22: new FormControl(model?.irS10Osnovica22),
      irS10Pdv22: new FormControl(model?.irS10Pdv22),

      iR20Osnovica22: new FormControl(model?.iR20Osnovica22),
      iR20Pdv22: new FormControl(model?.iR20Pdv22),
      iR10Osnovica22: new FormControl(model?.iR10Osnovica22),
      iR10Pdv22: new FormControl(model?.iR10Pdv22),

      ukupno20Osnovica22: new FormControl(model?.ukupno20Osnovica22),
      ukupno20Pdv22: new FormControl(model?.ukupno20Pdv22),
      ukupno10Osnovica22: new FormControl(model?.ukupno10Osnovica22),
      ukupno10Pdv22: new FormControl(model?.ukupno10Pdv22),

      odbitak20Pdv22: new FormControl(model?.odbitak20Pdv22),
      odbitak10Pdv22: new FormControl(model?.odbitak10Pdv22),


      //2.3
      irS20Osnovica23: new FormControl(model?.irS20Osnovica23),
      irS20Pdv23: new FormControl(model?.irS20Pdv23),
      irS10Osnovica23: new FormControl(model?.irS10Osnovica23),
      irS10Pdv23: new FormControl(model?.irS10Pdv23),

      iR20Osnovica23: new FormControl(model?.iR20Osnovica23),
      iR20Pdv23: new FormControl(model?.iR20Pdv23),
      iR10Osnovica23: new FormControl(model?.iR10Osnovica23),
      iR10Pdv23: new FormControl(model?.iR10Pdv23),

      ukupno20Osnovica23: new FormControl(model?.ukupno20Osnovica23),
      ukupno20Pdv23: new FormControl(model?.ukupno20Pdv23),
      ukupno10Osnovica23: new FormControl(model?.ukupno10Osnovica23),
      ukupno10Pdv23: new FormControl(model?.ukupno10Pdv23),

      odbitak20Pdv23: new FormControl(model?.odbitak20Pdv23),
      odbitak10Pdv23: new FormControl(model?.odbitak10Pdv23),


      //2.4
      irS20Osnovica24: new FormControl(model?.irS20Osnovica24),
      irS10Osnovica24: new FormControl(model?.irS10Osnovica24),

      iR20Osnovica24: new FormControl(model?.iR20Osnovica24),
      iR10Osnovica24: new FormControl(model?.iR10Osnovica24),

      ukupno20Osnovica24: new FormControl(model?.ukupno20Osnovica24),
      ukupno10Osnovica24: new FormControl(model?.ukupno10Osnovica24),

      odbitak20Pdv24: new FormControl(model?.odbitak20Pdv24),
      odbitak10Pdv24: new FormControl(model?.odbitak10Pdv24),


      //2.5
      irS20Osnovica25: new FormControl(model?.irS20Osnovica25),
      irS20Pdv25: new FormControl(model?.irS20Pdv25),
      irS10Osnovica25: new FormControl(model?.irS10Osnovica25),
      irS10Pdv25: new FormControl(model?.irS10Pdv25),

      iR20Osnovica25: new FormControl(model?.iR20Osnovica25),
      iR20Pdv25: new FormControl(model?.iR20Pdv25),
      iR10Osnovica25: new FormControl(model?.iR10Osnovica25),
      iR10Pdv25: new FormControl(model?.iR10Pdv25),

      ukupno20Osnovica25: new FormControl(model?.ukupno20Osnovica25),
      ukupno20Pdv25: new FormControl(model?.ukupno20Pdv25),
      ukupno10Osnovica25: new FormControl(model?.ukupno10Osnovica25),
      ukupno10Pdv25: new FormControl(model?.ukupno10Pdv25),

      odbitak20Pdv25: new FormControl(model?.odbitak20Pdv25),
      odbitak10Pdv25: new FormControl(model?.odbitak10Pdv25),

      //2.6
      irS20Osnovica26: new FormControl(model?.irS20Osnovica26),
      irS10Osnovica26: new FormControl(model?.irS10Osnovica26),

      iR20Osnovica26: new FormControl(model?.iR20Osnovica26),
      iR10Osnovica26: new FormControl(model?.iR10Osnovica26),

      ukupno20Osnovica26: new FormControl(model?.ukupno20Osnovica26),
      ukupno10Osnovica26: new FormControl(model?.ukupno10Osnovica26),

      odbitak20Pdv26: new FormControl(model?.odbitak20Pdv26),
      odbitak10Pdv26: new FormControl(model?.odbitak10Pdv26),

      //3.1
      placeniPdv20: new FormControl(model?.placeniPdv20),
      placeniPdv10: new FormControl(model?.placeniPdv10),
      //3.2
      placeniPdvOdb20: new FormControl(model?.placeniPdvOdb20),
      placeniPdvOdb10: new FormControl(model?.placeniPdvOdb10),
      //3.3
      ukupnoPlaceniPdv: new FormControl(model?.ukupnoPlaceniPdv),
      //3.4
      ukupnoPlaceniPdvOdb: new FormControl(model?.ukupnoPlaceniPdvOdb),


      //4.1
      ukupnoPdvPolj: new FormControl(model?.ukupnoPdvPolj),
      //4.2
      ukupnoPdvPoljOdb: new FormControl(model?.ukupnoPdvPoljOdb),


      //5.1
      sticanjePravaOdb: new FormControl(model?.sticanjePravaOdb),
      naknadnoSticanjePravaOdb: new FormControl(model?.naknadnoSticanjePravaOdb),
      odlukaPoreskogOrgana51: new FormControl(model?.odlukaPoreskogOrgana51),
      povecanjeSrazmernogOdb: new FormControl(model?.povecanjeSrazmernogOdb),
      sticanjePravaOdbPdv: new FormControl(model?.sticanjePravaOdbPdv),
      drugiOsnov51: new FormControl(model?.drugiOsnov51),


      //5.2
      prestanakUslova: new FormControl(model?.prestanakUslova),
      odlukaPoreskogOrgana52: new FormControl(model?.odlukaPoreskogOrgana52),
      smanjenjeSrazmernogOdb: new FormControl(model?.smanjenjeSrazmernogOdb),
      podnosenjeZahteva: new FormControl(model?.podnosenjeZahteva),
      odlukaCarinskogOrgana: new FormControl(model?.odlukaCarinskogOrgana),
      smanjenjePdv: new FormControl(model?.smanjenjePdv),
      drugiOsnov52: new FormControl(model?.drugiOsnov52),

    });
  }

  get jeEdit(): boolean {
    if (!this._form) {
      return false;
    }
    return this._form!.value!.id! > 0;
  }

  resetForm() {
    this._isInitialized = false;
    this._init({}, false);
  }
}
