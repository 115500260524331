import { Injectable } from '@angular/core';
import { IRacunInfoDto, VezaniRacunDto } from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class RacunInfoService {
  constructor() {}

  racunInfoDto$ = new BehaviorSubject<IRacunInfoDto>(null);

  private vezaniRacuni$ = new BehaviorSubject<VezaniRacunDto[]>([]);
  
  get getRacunInfoDto() {
    return this.racunInfoDto$.asObservable();
  }

  set setRacunInfoDto(data: IRacunInfoDto) {
    this.racunInfoDto$.next(data);
  }

  get getVezaneRacune() {
    return this.racunInfoDto$.value.vezaniRacuniDto;
  }

  reloadDocuments$ = new BehaviorSubject<number | undefined>(undefined);
  get getReloadedDocuments() {
    return this.reloadDocuments$.asObservable();
  }
  set setReloadDocuments(racunId: number) {
    this.reloadDocuments$.next(racunId);
  }

  racunId$ = new BehaviorSubject<number>(null);
  get getRacunId() {
    return this.racunId$.asObservable();
  }
  set setRacunId(data: number) {
    this.racunId$.next(data);
  }
  
  get getVezaniRacuni(){
    return this.vezaniRacuni$.asObservable();
  }

  get getVezaniRacuniValue(){
    return this.vezaniRacuni$.value;
  }

  set setVezaniRacuni(data: VezaniRacunDto[]){
    this.vezaniRacuni$.next(data);
  }

  vezaniRacuniChanged$ = new BehaviorSubject<boolean>(false);

  set setVezaniRacuniChanged(value: boolean) {
    this.vezaniRacuniChanged$.next(value);
  }

  get getVezaniRacuniChanged() {
    return this.vezaniRacuniChanged$.asObservable();
  }
}
