import { Injectable, OnDestroy } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  BuyerIdentification,
  BuyerOptionalField,
  EsdcCardStatus,
  EsdcCardStatusChangeNotification,
  IInvoiceItemDto,
  IInvoicePaymentDto,
  IInvoiceRequestDto,
  IJournalOptionDto,
  InvoicePaymentDto,
  InvoiceTransaction,
  InvoiceType,
  IReferentniFiskalniRacunDto,
  PaymentType,
  RobaDto,
  TransactionType,
  VrstaFiskalnogRacuna
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FiskalniRacunFormService } from './fiskalni-racun-form.service';

export const vrstaFiskalnogMap: Map<
  VrstaFiskalnogRacuna,
  InvoiceType
> = new Map<VrstaFiskalnogRacuna, InvoiceType>([
  [VrstaFiskalnogRacuna.PROMET, InvoiceType.NORMAL],
  [VrstaFiskalnogRacuna.PROMET_REFUNDACIJA, InvoiceType.NORMAL],
  [VrstaFiskalnogRacuna.PREDRACUN, InvoiceType.PROFORMA],
  [VrstaFiskalnogRacuna.PREDRACUN_REFUNDACIJA, InvoiceType.PROFORMA],
  [VrstaFiskalnogRacuna.OBUKA, InvoiceType.TRAINING],
  [VrstaFiskalnogRacuna.OBUKA_REFUNDACIJA, InvoiceType.TRAINING],
  [VrstaFiskalnogRacuna.AVANS, InvoiceType.ADVANCE],
  [VrstaFiskalnogRacuna.AVANS_REFUNDACIJA, InvoiceType.ADVANCE],
]);

export const vrstaPaymentStr: Map<number, string> = new Map<number, string>([
  [PaymentType.OTHER, 'Drugo bezgotovinsko'],
  [PaymentType.CASH, 'Gotovina'],
  [PaymentType.CARD, 'Platna kartica'],
  [PaymentType.CHECK, 'Ček'],
  [PaymentType.WIRE_TRANSFER, 'Prenos na račun'],
  [PaymentType.VOUCHER, 'Vaučer'],
  [PaymentType.MOBILE_MONEY, 'Instant plaćanje'],
]);

export const vrstaTransakcijeStr: Map<TransactionType, string> = new Map<
  TransactionType,
  string
>([
  [TransactionType.SALE, 'Prodaja'],
  [TransactionType.REFUND, 'Refundacija'],
]);

@Injectable({
  providedIn: 'root',
})
export class FiskalniRacunService extends BaseService implements OnDestroy {
  /** Subscription */
  private _subs: Subscription = new Subscription();

  /**
   *  Postavljanje forme
   */
  form$ = new BehaviorSubject<FiskalniRacunForm>(null);

  /**
   * Proveravamo dali je Save form clicked zbog komponenti poput autocomplete itd..
   */
  formSaveClicked$ = new BehaviorSubject<boolean>(null);

  /**
   * Proveravamo status ESDC card (bezbednosnog elementa)
   */
  esdcCardStatus$ = new BehaviorSubject<EsdcCardStatusChangeNotification>({
    backgroundColor: '#FF5959',
    cardStatus: EsdcCardStatus.NOT_INSERTED,
  } as EsdcCardStatusChangeNotification);

  /**
   * UID trenutno ubacenog bezbednosnog elementa
   */
  esdcUid$ = new BehaviorSubject<string>(null);

  /**
   * Prikazujemo sva 4 tipa placanja
   */
  shouldShowPaymentMethods$ = new BehaviorSubject<boolean>(true);

  /**
   * Prikazujemo samo tip sva placanja
   */
  shouldShowSvaPlacanjaMethod$ = new BehaviorSubject<boolean>(false);

  /**
   * Prikazujemo samo tip sva placanja
   */
  shouldBlockAkcijaButton$ = new BehaviorSubject<boolean>(false);

  /**
   * Handle click on custom submit button to invoke dialog onSave()
   */
  shouldSubmitFiskalizujForm$ = new BehaviorSubject<boolean>(false);

  /**
   * Handle click on custom close button to invoke dialog close()
   */
  shouldCloseDialog$ = new BehaviorSubject<boolean>(false);

  /**
   * Koristimo uz metodu SvaPlacanja kada sa back-a vracamo grupisane Invoice Payment-e
   */
  invoicePayments$ = new BehaviorSubject<InvoicePaymentDto[]>(null);

  /**
   *  Artikli/Roba
   */
  artikli$ = new BehaviorSubject<RobaDto[]>([]);
  artikliCount$ = new BehaviorSubject<number>(0);

  journalOptionsSubject$ = new BehaviorSubject<IJournalOptionDto | null>(null);

  constructor(private _fiskalniFS: FiskalniRacunFormService) {
    super();
  }

  set setForm(value: FiskalniRacunForm) {
    this.form$.next(value);
  }

  get getForm(): Observable<FiskalniRacunForm> {
    return this.form$.asObservable();
  }

  get getFormObject(): FiskalniRacunForm {
    return this.form$.getValue();
  }

  set setFormSaveClicked(value: boolean) {
    this.formSaveClicked$.next(value);
  }

  get getFormSaveClicked(): Observable<boolean> {
    return this.formSaveClicked$.asObservable();
  }

  set setArtikli(value: RobaDto[]) {
    this.artikli$.next(value);
  }

  get getArtikli() {
    return this.artikli$.asObservable();
  }

  set setArtikliCount(value: number) {
    this.artikliCount$.next(value);
  }

  get getArtikliCount() {
    return this.artikliCount$.asObservable();
  }

  set setPredefinisaniTekst(value: IJournalOptionDto){
    this.journalOptionsSubject$.next(value);
  }

  get getPredefinisaniTekst(){
    return this.journalOptionsSubject$.asObservable();
  }

  get currentPredefinisaniTekst(): IJournalOptionDto | null {
    return this.journalOptionsSubject$.getValue();
  }

  set setShouldShowPaymentMethods(value: boolean) {
    this.shouldShowPaymentMethods$.next(value);
  }

  get getShouldShowPaymentMethods() {
    return this.shouldShowPaymentMethods$.asObservable();
  }

  set setShouldShowSvaPlacanjaMethod(value: boolean) {
    this.shouldShowSvaPlacanjaMethod$.next(value);
  }

  get getShouldShowSvaPlacanjaMethod() {
    return this.shouldShowSvaPlacanjaMethod$.asObservable();
  }

  set setShouldBlockAkcijaButton(value: boolean) {
    this.shouldBlockAkcijaButton$.next(value);
  }

  get getShouldBlockAkcijaButton() {
    return this.shouldBlockAkcijaButton$.asObservable();
  }

  set setShouldSubmitFiskalizujForm(value: boolean) {
    this.shouldSubmitFiskalizujForm$.next(value);
  }

  get getShouldSubmitFiskalizujForm() {
    return this.shouldSubmitFiskalizujForm$.asObservable();
  }

  set setShouldCloseDialog(value: boolean) {
    this.shouldCloseDialog$.next(value);
  }

  get getShouldCloseDialog() {
    return this.shouldCloseDialog$.asObservable();
  }

  set setInvoicePayments(payments: InvoicePaymentDto[]) {
    this.invoicePayments$.next(payments);
  }

  set setInvoiceTransaction(invoiceTransaction: InvoiceTransaction) {
    this.getFormObject.controls.transactionType?.patchValue(
      invoiceTransaction.transactionType
    );
    this.getFormObject.controls.invoiceType?.patchValue(
      invoiceTransaction.invoiceType
    );
  }

  get getTransactionType(): TransactionType {
    if (!this.getForm) {
      return TransactionType.SALE;
    }

    return this.getFormObject!.controls!.transactionType!.value!;
  }

  /**
   *  Stavke fiskalnog racuna
   */
  private stavkeForRefundInitialized = new BehaviorSubject<boolean>(false);

  get getStavkeForRefundInitializedObservable(): Observable<boolean> {
    return this.stavkeForRefundInitialized.asObservable();
  }

  set setStavkeForRefundInitialized(value: boolean){
    this.stavkeForRefundInitialized.next(value);
  }

  get getStavkeFiskalnogArray(): FormArray {
    if (!this.getForm) {
      return new FormArray([]);
    }
    return this.getFormObject.controls.invoiceItems as FormArray;
  }

  get getStavkePlacanjaFiskalnogArray(): FormArray {
    if (!this.getForm) {
      return new FormArray([]);
    }
    return this.getFormObject.controls.invoicePayments as FormArray;
  }

  get getStavkePlacanjaFiskalnogValue(): number {
    let uplaceno = 0;
    this.getStavkePlacanjaFiskalnogArray.value.forEach(
      (item: InvoicePaymentDto) => {
        uplaceno += item.amount!;
      }
    );
    return uplaceno;
  }

  addStavkuFiskalnogRacuna(stavka: IInvoiceItemDto) {
    this.getStavkeFiskalnogArray.push(
      this._fiskalniFS.GetInvoiceItemFormGroup(stavka)
    );
  }

  addStavkuPlacanjaFiskalnogRacuna(stavka: IInvoicePaymentDto) {
    this.getStavkePlacanjaFiskalnogArray.push(
      this._fiskalniFS.GetInvoicePaymentGroup(stavka)
    );
  }

  roundStavke(){
    let stavke = this.getStavkeFiskalnogArray.value;
    this.getStavkeFiskalnogArray.clear();
    stavke.forEach((stavka: IInvoiceItemDto) => {
      stavka.unitPrice = this.roundToTwoDecimals(stavka.unitPrice!);
      stavka.totalAmount = stavka.unitPrice * stavka!.quantity!;
      this.addStavkuFiskalnogRacuna(stavka);
    });
  }
   roundToTwoDecimals(value: number): number{
    return Math.round(value*100)/100;
  }

  clearStavkePlacanjaFiskalnogRacuna(){
    this.getStavkePlacanjaFiskalnogArray.clear();
  }

  /**
   * Avansne uplate fiskalnog racuna
   */

  // koristimo jer za avansni fiskalni mozemo imati samo jednu stavku
  stavkeFiskalnogRacunaExists(naziv: string, label: string): boolean {
    var postoji = false;
    this.getStavkeFiskalnogArray.controls.forEach((x, idx) => {
      const item = x.value as IInvoiceItemDto;
      if (item.name === naziv && item.labels?.includes(label)) {
        postoji = true;
      }
    });
    return postoji;
  }

  /**
   *  Updates and Adds
   */

  addAvansnaUplataZaStavkuFiskalnogRacuna(invoiceItem: IInvoiceItemDto) {
    this.addStavkuFiskalnogRacuna(invoiceItem);
  }

  addStavkaOrUpdateKolicinaZaStavkuFiskalnogRacuna(
    invoiceItem: IInvoiceItemDto
  ) {
    var postoji = false;
    if (this.getStavkeFiskalnogArray.length !== 0) {
      this.getStavkeFiskalnogArray.controls.forEach((x, idx) => {
        const item = x.value as IInvoiceItemDto;
        if (item.name === invoiceItem.name) {
          this.updateKolicinaZaStavkuFiskalnogRacuna(
            invoiceItem.quantity ?? 1,
            idx
          );
          postoji = true;
        }
      });
      if (!postoji) {
        this.addStavkuFiskalnogRacuna(invoiceItem);
      }
    } else {
      this.addStavkuFiskalnogRacuna(invoiceItem);
    }
  }

  addStavkeZaRefundacijuFiskalnogRacuna(invoiceItems: IInvoiceItemDto[]) {
    this.resetStavkeFiskalnogRacuna();
    invoiceItems.forEach((item) => {
      this.addStavkuFiskalnogRacuna(item);
    });
    this.setStavkeForRefundInitialized = true;
  }

  updateKolicinaZaStavkuFiskalnogRacuna(value: number, idx: number) {
    this.getStavkeFiskalnogArray.at(idx).get('quantity')?.patchValue(value);
  }

  /**
   *  Setters
   */
  set setBuyerIdentification(value: BuyerIdentification) {
    this.getFormObject.get("buyerIdentification")?.patchValue(value);
  }

  set setBuyerId(value: string) {
    this.getFormObject.get("buyerId")?.patchValue(value);
  }

  set setBuyerOptionalField(value: BuyerOptionalField) {
    this.getFormObject.get("buyerOptionalField")?.patchValue(value);
  }

  set setBuyerCostCenterId(value: string) {
    this.getFormObject.get("buyerCostCenterId")?.patchValue(value);
  }

  setVrstaFiskalnogRacuna(vrsta: VrstaFiskalnogRacuna) {
    // ako je VrstaFiskalnogRacuna paran broj onda je u pitanju refundacija
    vrsta % 2 == 0
      ? this.getFormObject
          .get('transactionType')
          ?.patchValue(TransactionType.REFUND)
      : this.getFormObject
          .get('transactionType')
          ?.patchValue(TransactionType.SALE);

    vrstaFiskalnogMap.forEach(
      (value: InvoiceType, key: VrstaFiskalnogRacuna) => {
        if (vrsta === key) {
          this.getFormObject.get('invoiceType')?.patchValue(value);
        }
      }
    );
  }

  setReferentniRacun(value: IReferentniFiskalniRacunDto) {
    const date = new Date();
    const now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    const now_utc2 = Date.UTC(
      value!.referentDocumentDT!.getUTCFullYear(),
      value!.referentDocumentDT!.getUTCMonth(),
      value!.referentDocumentDT!.getUTCDate(),
      value!.referentDocumentDT!.getUTCHours(),
      value!.referentDocumentDT!.getUTCMinutes(),
      value!.referentDocumentDT!.getUTCSeconds()
    );

    console.log('new date utc: ', new Date(now_utc));
    console.log('new date to iso: ', date.toISOString());
    console.log('back date utc: ', new Date(now_utc2));
    console.log('back date to is: ', value!.referentDocumentDT!.toISOString());

    this.getFormObject.get('referentniFiskalniRacunDto')?.patchValue(value);

    this.setBuyerInfo(value.buyerIdentification, value.buyerId, value.buyerOptionalField, value.buyerCostCenterId);
  }

  setBuyerInfo(
    buyerIdentification: BuyerIdentification | undefined,
    buyerId: string | undefined,
    buyerOptionalField: BuyerOptionalField | undefined,
    buyerCostCenterId: string | undefined
  ){
    if (
      buyerIdentification !== BuyerIdentification.EMPTY &&
      buyerIdentification
    ) {
      this.setBuyerIdentification = buyerIdentification;
    }
    if(buyerId){
      this.setBuyerId = buyerId.split(':')[1];
    }
    if(buyerOptionalField){
      this.setBuyerOptionalField = buyerOptionalField;
    }
    if(buyerCostCenterId){
      this.setBuyerCostCenterId = buyerCostCenterId.split(':')[1];
    }
  }

  set setEsirVreme(value: Date){
    this.getFormObject.controls.dateAndTimeOfIssue!.patchValue(value);
  }

  /**
   *  Getters
   */

  // Vracanje ukupnog iznosa fiskalnog racuna (ukupno,popust i zaNaplatu) */
  getUkupanIznosFiskalnogRacuna(): number {
    var ukupanIznos = 0;
    this.getStavkeFiskalnogArray.value.forEach((x: IInvoiceItemDto) => {
      ukupanIznos += x.unitPrice! * x.quantity! ?? 0;
    });

    return ukupanIznos;
  }

  // Vracanje ukupnog iznosa fiskalnog racuna umanjen za avans */
  get getUkupanIznosUmanjenZaAvansFiskalnogRacuna(): number {
    var ukupanIznos = 0;
    this.getStavkeFiskalnogArray.value.forEach((x: IInvoiceItemDto) => {
      ukupanIznos += x.unitPrice! * x.quantity! ?? 0;
    });

    if(this.hasAvans && this.getFormObject.value.referentniFiskalniRacunDto!.amount! > 0)
    {
      ukupanIznos -= this.getFormObject.value.referentniFiskalniRacunDto!.amount!;
    }

    return ukupanIznos;
  }

  get getAvansValue(): number{
    return this.getFormObject.value.referentniFiskalniRacunDto!.amount!;
  }

  get hasAvans(){
    if(this.getFormObject.value.referentniFiskalniRacunDto === null){
      return false;
    }
    return this.getFormObject.value.referentniFiskalniRacunDto!.invoiceType === InvoiceType.ADVANCE &&
      this.getFormObject.value.referentniFiskalniRacunDto!.transactionType === TransactionType.REFUND;
  }

  getUkupanIznosPlacanjaFiskalnogRacuna(): number {
    const invoicePayments = this.getStavkePlacanjaFiskalnogArray.controls.map(
      (x) => x.value
    ) as InvoicePaymentDto[];
    return invoicePayments
      .map((x) => x.amount)
      .reduce((sum, currentValue) => sum! + currentValue!, 0)!;
  }

  get getEsdcCardStatus() {
    return this.esdcCardStatus$.asObservable();
  }

  set setEsdcCardStatus(isActive: EsdcCardStatusChangeNotification) {
    this.esdcCardStatus$.next(isActive);
  }

  get getEsdcUid() {
    return this.esdcUid$.asObservable();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  /**
   *  Resets
   */

  resetForm() {
    this.getFormObject.reset();
  }

  resetStavkeFiskalnogRacuna() {
    this.getStavkeFiskalnogArray.clear();
    this.setStavkeForRefundInitialized = false;
  }

  resetStavkePlacanjaFiskalnogRacuna() {
    this.getStavkePlacanjaFiskalnogArray.clear();
  }

  resetReferentniRacun() {
    this.getFormObject.controls.referentniFiskalniRacunDto?.reset();
    this.resetStavkeFiskalnogRacuna();
  }

  resetEsirVreme(){
    this.getFormObject.controls.dateAndTimeOfIssue?.reset();
  }
}

export declare type FiskalniRacunForm = FormGroupTypeSafe<IInvoiceRequestDto>;
