import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import {
  MojaFirmaClient,
  ResultOfIReadOnlyCollectionOfSenderSignatureDropdownDto,
  SenderSignatureDropdownDto
} from '@kodit/core/data-api';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-email-dropdown',
  templateUrl: './email-dropdown.component.html',
  styleUrls: ['./email-dropdown.component.scss'],
})
export class EmailDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor(
    private _client: MojaFirmaClient
  ) {}

  ngOnInit(): void {
    this._load();
  }

  private _load(){
    this._subs.add(
      this._client.getSenderSignaturesDropdown().subscribe((res: ResultOfIReadOnlyCollectionOfSenderSignatureDropdownDto) =>{
        this.items = res.data!.map((x: SenderSignatureDropdownDto) => ({
          value: x.id,
          label: x.email,
          title: x.email,
        }));
        this.formCtrl.patchValue(this.items[0].value)
        this.formCtrl.updateValueAndValidity();
      })
    );
  }


  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
