import { Component, OnInit } from '@angular/core';
import { CreateDelovodniDokumentCommand, DelovodniciClient, DelovodnikInfoDto, FileParameter } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  DelovodniDokumentForm,
  DelovodnikService,
} from 'libs/core/moduli/podesavanje/src/lib/delovodnik/delovodnik-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-delovodni-dokument-form',
  templateUrl: './delovodni-dokument-form.component.html',
  styleUrls: ['./delovodni-dokument-form.component.scss'],
})
export class DelovodniDokumentFormComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();
  /** Forms */
  form!: DelovodniDokumentForm;

  /** Props */
  delovodnikDto: DelovodnikInfoDto | undefined;
  file: FileParameter | undefined;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _delovodnikService: DelovodnikService,
    private _client: DelovodniciClient,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) {}

  onUpload(file: File) {
    this.file = { fileName: file.name, data: file };
  }

  ngOnInit(): void {
    this.delovodnikDto = this._dialogConfig.data.delovodnikDto;
    this.form = this._delovodnikService.getDelovodniDokumentForm(
      this.delovodnikDto?.id!,
      this.delovodnikDto?.kategorijaId
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._sharedService.displayLoadingScreen(
      true,
      'Kreiranje delovodnog dokumenta...'
    );

    this._subs.add(
      this._client
        .createDelovodniDokument(
          this.form.value as CreateDelovodniDokumentCommand
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.messages![0]);
          } else {
            this._alertService.addWarnMsg(result.messages![0]);
          }
          this._dialogRef.close(result);
        })
    );
  }
}
