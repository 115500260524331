import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddPovezaniRacunCommand, GetVezaniRacunAutocompleteQuery, RacunDto, RacuniClient, StatusRacuna, TipRacuna, VezaniRacunDto, VrstaRacuna } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { RacunInfoService } from '../../racun-info/racun-info.service';

@Component({
  selector: 'kodit-add-povezani-racun-form',
  templateUrl: './add-povezani-racun-form.component.html',
  styleUrls: ['./add-povezani-racun-form.component.scss']
})
export class AddPovezaniRacunFormComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  /** Props */
  racuniToAdd: VezaniRacunDto[] = [];
  racuniSuggestions: VezaniRacunDto[] = [];
  tipoviRacuna: TipRacuna[];
  statusRacuna: StatusRacuna[] = [StatusRacuna.NACRT];
  tip: TipRacuna;
  vrsta: VrstaRacuna;
  alreadyVezaniRacuni: VezaniRacunDto[] = [];
  removedRacuni: VezaniRacunDto[] = [];
  trenutnoDodato: VezaniRacunDto[] = [];
  pdfFileUrl: string;


  constructor(
    private _dialogRef: DynamicDialogRef,
    private _alertService: AlertService,
    private _racuniClient: RacuniClient,
    public dialogConfig: DynamicDialogConfig,
    public _racunInfoService: RacunInfoService

  ) { }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );

    this.tip = this.dialogConfig.data.racun.tip;
    this.vrsta = this.dialogConfig.data.racun.vrsta;
    if(this.tip == TipRacuna.AVANSNI_RACUN && this.vrsta == VrstaRacuna.IZLAZNI){
      this.tipoviRacuna = [TipRacuna.PREDRACUN];
    }else if(this.tip == TipRacuna.IZLAZNA_FAKTURA && this.vrsta == VrstaRacuna.IZLAZNI){
      this.tipoviRacuna = [TipRacuna.OTPREMNICA]
    }else if(this.tip == TipRacuna.PREDRACUN){
      this.tipoviRacuna = [TipRacuna.AVANSNI_RACUN]
    }else{
      this.tipoviRacuna = [TipRacuna.IZLAZNA_FAKTURA]
    }

    // racuni koji su vec vezani u momentu otvaranja dialoga
    this.trenutnoDodato = [...this._racunInfoService.getVezaneRacune];
  
  }

  private save() {
    if (!this.racuniToAdd || this.racuniToAdd.length === 0) {
      this._alertService.addWarnMsg('Morate uneti bar jedan račun za povezivanje');
      return;
    }

    this._subs.add(
      this._racuniClient
        .addVezaniRacuni(new AddPovezaniRacunCommand({ racunId: this.dialogConfig.data.racun.id, vezaniRacuniDto: this.racuniToAdd }))
        .subscribe((result) => {
          if (result.succeeded) {
            this._racunInfoService.setVezaniRacuni = result.data;
            this._racunInfoService.setVezaniRacuniChanged = true;
            this._alertService.addSuccessMsg("Uspešno ste povezali račun.");
            this._dialogRef.close(result.data);
          } else {
            this._alertService.addWarnMsg(result.messages![0]);
          }
        })
    );
  }

  searchRacuni(event: any) {
    this._subs.add(
      this._racuniClient
        .getVezaniRacunAutocomplete(
          new GetVezaniRacunAutocompleteQuery({
            searchValue: event.query,
            jeIzmenaOsnovice: false,
            includeTipoveRacuna: this.tipoviRacuna,
            includeStatuseRacuna: this.statusRacuna,
          })
        )
        .subscribe((result) => {
          this.racuniSuggestions = result.data.filter((x) =>
            this.filterVezani(x.racunId)
          );
        })
    );
  }

  filterVezani(id: number): boolean {
    let alreadyAdded: boolean = false;
    let racuni = this.trenutnoDodato.concat(this.racuniToAdd);
    racuni.forEach((r) => {
      if (r.racunId === id) {
        alreadyAdded = true;
        return;
      }
    });
    return (
      racuni.findIndex((x) => x.racunId == id) === -1 &&
      !alreadyAdded
    );
  }

  onRacunSelect(racun: VezaniRacunDto) {
    this.racuniToAdd.push(racun);
    this.trenutnoDodato.push(racun);
  }

  onRemoveVezaniRacun(index: number) {
    let racunToDelete = this.racuniToAdd.splice(index, 1)[0];
    let indexToRemove = this.trenutnoDodato.findIndex(x => x.racunId == racunToDelete.racunId);
    this.trenutnoDodato.splice(indexToRemove, 1);
  }

  ngOnDestroy(): void {}
}
